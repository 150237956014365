<template>
  <b-modal
    ref="modal-verify"
    id="modal-verify"
    centered
    class="modal-child"
    hide-header
    hide-footer
    title="Card Activation"
    @close="hideModal"
  >
    <div class="d-flex justify-content-between top-modal mb-1">
      <h3
        id="title-modal"
        data-testid="title-modal"
        class="font-medium text-primary"
      >
        {{ $t('payment.creditCard.modalVerifyCard.titleModal') }}
      </h3>
      <div>
        <feather-icon
          id="btn-close-modal"
          data-testid="btn-close-modal"
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div
      v-if="verifyStatus"
      class="notification-container"
    >
      <img
        data-testid="img-active-card-success"
        alt="success"
        :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
      >
      <h3
        data-testid="content-active-card-success"
        class="notification-title"
      >
        {{ $t('payment.creditCard.modalVerifyCard.cardActiveSuccess') }}
      </h3>
      <p class="notification-content mb-3">
        {{ $t('payment.creditCard.modalVerifyCard.textYouCardEnding') }}
        <span
          data-testid="last-number-card-success"
          class="font-20 font-weight-600"
          style="color: #15b4bf"
        >{{ cardInfo.last4 }}</span>
        {{ $t('payment.creditCard.modalVerifyCard.textIsVerifiedSuccess') }}
      </p>

      <b-button
        id="button-close-active-success"
        data-testid="button-close-active-success"
        class="mb-2"
        variant="primary"
        @click="hideModal"
      >
        {{ $t('common.btnClose') }}
      </b-button>
    </div>
    <div v-else>
      <div class="d-flex align-items-center mb-1">
        <feather-icon
          size="16"
          icon="FileTextIcon"
          class="text-success"
          style="margin-right: 5px"
        />
        <p
          id="btn-go-to-guild-active-code"
          data-testid="btn-go-to-guild-active-code"
          class="text-green cursor-pointer mb-0"
          @click="goToGuideActiveCard"
        >
          {{ $t('payment.creditCard.modalVerifyCard.howToActivateCreditCard') }}
        </p>
      </div>
      <p class="text-color">
        {{ $t('payment.creditCard.modalVerifyCard.textClickGetActivationCode') }}
        <span class="text-medium">{{ $t('payment.creditCard.modalVerifyCard.textIBankingStatement') }}</span>,
        {{ $t('payment.creditCard.modalVerifyCard.textYour') }}
        <span class="text-medium">{{ $t('payment.creditCard.modalVerifyCard.textSMSMessages') }}</span>,
        {{ $t('payment.creditCard.modalVerifyCard.textOrYour') }}
        <span class="text-medium">{{ $t('payment.creditCard.modalVerifyCard.textEmailInbox') }}</span>
        {{ $t('payment.creditCard.modalVerifyCard.textToGetAnCode') }}.
      </p>
      <p class="text-color">
        {{ $t('payment.creditCard.modalVerifyCard.textActivationCodeFormat') }}:
        <span class="text-medium text-uppercase">{{ convertCodePlatform(platformName) }}*XXXX</span>.
        {{ $t('payment.creditCard.modalVerifyCard.textOnceYouReceiveIt') }} <span class="text-medium">XXXX</span>
        {{ $t('payment.creditCard.modalVerifyCard.textCodeAndEnterIt') }}.
      </p>

      <p class="font-italic mt-1 font-14">
        *{{ $t('payment.creditCard.modalVerifyCard.contentNoteVerify') }}.
      </p>

      <div
        class="form-active-code"
        :class="$device.mobile ? 'flex-column' :'flex-row align-items-center'"
      >
        <validation-observer ref="formValidation">
          <b-form
            class="form"
            style="margin-top: -18px"
          >
            <!-- OTP -->
            <b-form-group
              class="my-1 position-relative form-label"
            >
              <label class="font-16">
                {{ $t('payment.creditCard.modalVerifyCard.activationCode') }}
              </label>
              <validation-provider
                #default="{ errors }"
                :name="$t('payment.creditCard.modalVerifyCard.nameInputActivationCode')"
                rules="required|verifyCodeLength"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-input-group-prepend
                    v-if="!$device.mobile"
                    is-text
                    class="input-custom font-14"
                  >
                    {{ $t('payment.creditCard.modalVerifyCard.textSampleCode') }}:
                    <span class="text-uppercase">{{ convertCodePlatform(platformName) }}</span>*XXXX
                  </b-input-group-prepend>
                  <b-form-input
                    v-model.trim="code"
                    id="input-code-active"
                    data-testid="input-code-active"
                    name="code"
                    class="input-height"
                    placeholder="XXXX"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <p
                  v-if="$device.mobile"
                  class="mb-0"
                >
                  {{ $t('payment.creditCard.modalVerifyCard.textSampleCode') }}:
                  <span class="text-uppercase">{{ platformName }}</span>*XXXX
                </p>
                <small
                  id="error-active-code"
                  data-testid="error-active-code"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
        <btn-loading
          id="btn-active"
          data-testid="btn-active"
          size="sm"
          :class="$device.mobile ? 'm-0' : 'ml-1'"
          variant-convert="btn-submit"
          pill
          style="height: 45px"
          :disabled="code === '' || counterActive > 0"
          :loading="loading"
          @click="submitForm"
        >
          {{ $t('payment.creditCard.modalVerifyCard.textActivate') }} <span
            v-if="counterActive > 0"
            data-testid="countdown-time-activate"
          >{{ currentCountDownActiveTime }}</span>
        </btn-loading>
      </div>
      <div
        v-if="errorResentCode || errorVerifyCode"
      >
        <error-tag :error="errorResentCode || errorVerifyCode" />
      </div>
      <div class="mb-2">
        <div v-if="verifyStatus">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            id="btn-close-active-success-modal"
            data-testid="btn-close-active-success-modal"
            class="mr-1"
            variant="outline-secondary"
            @click="hideModal"
          >
            {{ $t('common.btnClose') }}
          </b-button>
        </div>
        <div
          v-else
          class="d-flex justify-content-center mt-2"
        >
          <btn-loading
            id="btn-active-later"
            data-testid="btn-active-later"
            size="sm"
            variant-convert="secondary"
            class="mr-50"
            pill
            @click="hideModal"
          >
            {{ $t('payment.creditCard.modalVerifyCard.btnActivateLater') }}
          </btn-loading>
          <btn-loading
            id="btn-get-activation-code"
            data-testid="btn-get-activation-code"
            variant-convert="primary"
            size="sm"
            pill
            :loading="initCardLoading"
            :disabled="disableActiveButton"
            @click="confirmResendCode"
          >
            {{ $t('payment.creditCard.modalVerifyCard.btnGetActivationCode') }}
            <span
              v-if="counter > 0"
              data-testid="countdown-time-get-code"
            >{{ currentCountDownTime }}</span>
          </btn-loading>
        </div>
      </div>
      <div class="user-hint mt-3 mb-2 d-flex">
        <user-hint />
      </div>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable no-plusplus */

import {
  BModal,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BButton,
} from 'bootstrap-vue'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, verifyCodeLength } from '@validations'
import { PaymentService } from '@/services'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'
import envMixin from '@/mixins/envMixin'
import Ripple from 'vue-ripple-directive'
import UserHint from '@/components/UserHint.vue'
import errorTag from '@/components/errorTag.vue'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('payment')
const { mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
export default {
  components: {
    errorTag,
    UserHint,
    BModal,
    BtnLoading,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BButton,
  },
  directives: {
    Ripple,
  },

  mixins: [toastification, envMixin],
  props: {
    initCardLoading: {
      type: Boolean,
      default: false,
    },
    errorResentCode: {
      type: String,
      default: '',
    },
    errorCode: {
      type: String,
      default: '',
    },
    timeCountDown: {
      type: Object,
      default: () => {
      },
    },
    // openAddFundModal: {
    //   type: Boolean,
    //   default: false,
    //   required: true,
    // },
  },

  data() {
    return {
      loading: false,
      code: '',
      countDown: 0,
      // validate
      required,
      verifyCodeLength,
      errorVerifyCode: '',
      errorActiveCode: '',
      verifyStatus: false,
      cardInfo: null,
      counter: 0,
      currentCountDownTime: '',
      counterActive: 0,
      currentCountDownActiveTime: '',
    }
  },

  computed: {
    ...mapGetters(['listCard']),

    disableActiveButton() {
      return (
        this.errorCode === 'error.block_request_init_credit_card'
        || this.errorCode === 'error.block_request_active_credit_card'
        || this.errorActiveCode === 'error.block_request_active_credit_card'
        || this.counter > 0
      )
    },

    minuteCountDown() {
      return time => Math.floor(time / 60)
    },

    secondCountDown() {
      return time => Math.floor(time % 60)
    },

    formatTimeCountDown() {
      return time => `(${time.minute}:${time.second < 10 ? `0${time.second}` : time.second})`
    },
  },

  watch: {
    timeCountDown: {
      handler(seconds) {
        if (seconds?.init > 0 || seconds.active > 0) {
          this.handleCountDownTime(seconds)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['getListCard']),
    ...mapActionsAuth(['getAccountInfo']),
    convertCodePlatform(platformName) {
      if (this.isEcomdyPlatform || this.isGyxenPlatform) {
        return platformName
      }
      return 'verifycode'
    },

    handleCountDownTime(seconds) {
      this.counter = seconds?.init
      this.counterActive = seconds?.active
      if (this.counter) {
        const intervalInit = setInterval(() => {
          this.currentCountDownTime = this.formatTimeCountDown({
            minute: this.minuteCountDown(this.counter),
            second: this.secondCountDown(this.counter),
          })
          this.counter--
          if (this.counter < 0) {
            clearInterval(intervalInit)
            this.$emit('resetError')
          }
        }, 1000)
      }
      if (this.counterActive) {
        const intervalActive = setInterval(() => {
          this.currentCountDownActiveTime = this.formatTimeCountDown({
            minute: this.minuteCountDown(this.counterActive),
            second: this.secondCountDown(this.counterActive),
          })
          this.counterActive--
          if (this.counterActive < 0) {
            clearInterval(intervalActive)
            this.errorVerifyCode = ''
            this.errorActiveCode = ''
          }
        }, 1000)
      }
    },

    confirmResendCode() {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t('payment.creditCard.modalVerifyCard.contentNoteVerify')}. ${this.$t('payment.creditCard.modalVerifyCard.textPleaseConfirmContinue')}?`,
          {
            title: this.$t('payment.creditCard.titleModalConfirmRemoveCard'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('payment.creditCard.modalVerifyCard.btnGetCode'),
            cancelTitle: this.$t('common.btnClose'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
            id: 'confirm-resend-code',
          },
        )
        .then(value => {
          if (value) {
            this.errorVerifyCode = ''
            this.$emit('resendCode')
          }
        })
    },

    resetForm() {
      this.verifyStatus = false
      this.code = ''
    },

    hideModal() {
      this.$refs['modal-verify'].hide()
      this.resetForm()
      this.errorVerifyCode = ''
      this.errorActiveCode = ''
      this.counter = 0
      this.currentCountDownTime = ''
      this.counterActive = 0
      this.currentCountDownActiveTime = ''
      this.$emit('resetError')
      this.$emit('resetErrorCode')
    },

    showModal() {
      this.$refs['modal-verify'].show()
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },

    startCountDown() {
      this.countDown = 10 * 60 // countdown in 10 minutes
      this.countDownTimer()
    },

    async submitForm() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        this.errorVerifyCode = ''
        this.$emit('resetError')
        try {
          this.loading = true
          const params = {
            code: this.code,
          }
          const value = await PaymentService.activeCard(params)
          const paymentMethod = value.data?.result?.paymentMethod
          this.loading = false
          this.getListCard()
          this.getAccountInfo()
          this.cardInfo = this.listCard.find(
            item => item.paymentMethodId === paymentMethod,
          )
          this.verifyStatus = true
          // this.toastSuccess('Verify card success!')
          // this.$refs['modal-verify'].hide()
        } catch (e) {
          this.loading = false
          if (e?.data?.code === 'error.verify_credit_card_limit_reached') {
            // this.toastFailure('Too many failed!. Please try again in 10 minutes.')
            this.errorVerifyCode = 'Too many failed!. Please try again in 10 minutes.'
            this.startCountDown()
          } else {
            // this.toastFailure(e.data.message)
            this.errorVerifyCode = e.data.message
            this.errorActiveCode = e?.data?.code
            const timeActive = e?.data?.args
            if (timeActive?.active > 0) {
              this.handleCountDownTime(timeActive)
            }
          }
        }
      }
    },

    goToGuideActiveCard() {
      window.open(this.activeYourCreditCard)
    },
  },
}
</script>
<style lang="scss">
#modal-verify {
  .modal-body,
  .modal-content,
  .modal-dialog {
    @media screen and (min-width: 1024px) {
      max-width: 650px;
    }
  }
}

.modal-footer-custom {
  border-top: none;
}

.input-custom {
  .input-group-text {
    background: rgba(22, 33, 62, 0.03) !important;
  }
}
</style>

<style lang="scss" scoped>
.btn-submit {
  min-width: 140px
}

.text-color {
  color: rgba(0, 0, 0, .7);
}

.text-medium {
  color: rgba(0, 0, 0, 1);
  font-family: "ProximaNovaA-medium", Helvetica, Arial, serif;
}

.text-uppercase {
  text-transform: uppercase;
}

.error-message-stripe {
  margin: 15px 0;
  background: rgba(225, 28, 78, 0.1);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: #000;

  .content-error {
    margin-left: 6px;
    margin-bottom: 0;
  }
}

.notification-container {
  text-align: center;

  .notification-title {
    margin: 1rem 0;
  }
}

.form-active-code {
  margin-top: 3rem;
  display: flex;

  .btn-submit {
    margin-left: 1rem;
  }
}
</style>

<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div data-testid="add-fund-container">
    <div
      v-if="isTikTokAppCenter"
      class="bg-white p-2 text-center tiktok-add-fund-container mb-2"
    >
      <p class="font-24 font-weight-600">
        Add funds
      </p>
      <p>Please click the button to continue the process of add fund</p>
      <btn-loading
        id="btn-continue-add-fund"
        data-testid="btn-continue-add-fund"
        variant-convert="btn-submit"
        @click="continueAddFund"
      >
        Continue
      </btn-loading>

      <div class="mt-2">
        <p>
          After a successful top-up, please click <span
            class="btn-refresh-add-fund"
            @click="handleRefreshPage"
          >
            <b-img
              width="18"
              class="icon-refresh-add-fund"
              :class="{ spin: accountLoading }"
              :src="require('@/assets/images/icons/ic-refresh.svg')"
            />
            Refresh
          </span> to get the latest data.
        </p>
      </div>
    </div>
    <div v-else>
      <div>
        <div
          v-if="!hasStepGuide"
          data-testid="btn-back-to-balance-allocation"
          class="d-flex align-items-center text-placeholder my-2 cursor-pointer"
          @click="$router.push('/payment?type=show_balance')"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            style="margin-right: 8px"
          />
          {{ $t('addFundPage.textBackToBalanceAllocation') }}
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <div
            v-if="hasStepGuide"
            class="amount-balance mb-1 d-flex justify-content-start flex-column"
          >
            <div class="d-flex align-items-center">
              <b-card-text class="ecomdy-balance m-0">
                <div class="d-flex align-items-center">
                  <span>
                    {{ $t('balance.textBalancePlatform', { platformName: isEmediaPlatform ? userName : platformName }) }}:
                  </span>
                  <span
                    id="current-balance"
                    data-testid="current-balance"
                    class="amount font-20 font-weight-bold"
                    style="margin-left: 4px;"
                  > ${{ amountFormat(amountBalance) }} </span>
                </div>
              </b-card-text>
              <div
                v-if="amountBalance > 0 && refundAutoMode"
                class="btn-auto-refund-container"
              >
                <btn-loading
                  class="btn-add-fund"
                  variant-convert="btn-submit-outline"
                  pill
                  @click="handleAutoRefund"
                >
                  Refund
                </btn-loading>
              </div>
            </div>
            <p
              v-if="(amountBalance < minTopUp) && !isSetupCostModel"
              class="text-warning-balance font-14 mb-0"
            >*{{ $t('SMB.payment.noteAddFundGreatThanMin', { minTopUp }) }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="hasStepGuide && totalRequestRefund > 0 && refundAutoMode"
        class="tabs-mf tabs-mf-customs"
      >
        <b-tabs
          class="tab-refund-on-step-guide"
        >
          <b-tab
            :active="tabActive === 'add_fund'"
            @click="handleSelectTab({tab: 'add_fund'})"
          >
            <template #title>
              <span class="tab-title">{{ $t('payment.addFund') }}</span>
            </template>

            <div :class="[!hasStepGuide ? 'bg-tabs' : 'bg-tabs-step']">
              <div v-if="isStepCreateAdAccount && isSetupCostModel">
                <setup-cost-success />
              </div>
              <b-tabs
                v-else
                ref="myTabs"
                id="list-payment-method"
                date-testid="list-payment-method"
                class="list-tabs-payment-has-step"
              >

                <div
                  v-if="isShowBtnScrollLeft"
                  class="btn-scroll-tab-left"
                  @click="scroll_left"
                >
                  <feather-icon
                    class="btn-chevrons"
                    icon="ChevronsLeftIcon"
                  />
                </div>
                <div
                  v-if="isShowBtnScrollRight"
                  class="btn-scroll-tab-right"
                  @click="scroll_right"
                >
                  <feather-icon
                    class="btn-chevrons"
                    icon="ChevronsRightIcon"
                  />
                </div>

                <b-tab
                  v-for="(payment, index) in listPaymentMethod"
                  :key="index"
                  :id="`trigger-${payment.id}-add-fund`"
                  :data-testid="`trigger-${payment.id}-add-fund`"
                  :active="tab === payment.id"
                  @click="handleUrlTab(payment.id)"
                >
                  <template #title>
                    <div class="w-100 h-100 d-flex flex-column justify-content-between">
                      <div class="content-title-tab">
                        <p
                          :id="`trigger-${payment.id}-add-fund-child`"
                          class="tab-title-payment-method"
                        >
                          {{ payment.name }}
                        </p>

                        <p
                          :id="`text-${payment.id}-fee-payment`"
                          class="text-fee-payment"
                        >
                          (Fee: {{ payment.gateFee }})
                        </p>
                      </div>

                      <div class="d-flex justify-content-end w-100">
                        <b-img
                          :data-testid="`${payment.id}-logo`"
                          alt="card"
                          :height="23"
                          :src="payment.LogoFullPayment"
                        />
                        <p
                          v-if="payment.id === 'payoneer-ticket' && hasPayoneer"
                          class="mb-0 font-12 font-weight-light text-placeholder"
                        >
                          Ticket
                        </p>
                      </div>
                    </div>
                  </template>
                  <credit-card v-if="payment.id === 'creditcard'" />
                  <paypal-card v-if="payment.id === 'paypal'" />
                  <payoneer v-if="payment.id === 'payoneer'" />
                  <PayoneerCard v-if="payment.id === 'payoneer-ticket'" />
                  <wise-transfer-card v-if="payment.id === 'wise'" />
                  <USDT-card v-if="payment.id === 'usdt'" />
                  <airwallex-new v-if="payment.id === 'airwallex'" />
                  <tazapay-card v-if="payment.id === 'tazapay'" />
                  <lian-lian-card v-if="payment.id === 'lianlian'" />
                  <PayFastCard v-if="payment.id === 'payfast'" />
                  <local-bank v-if="payment.id === 'local-bank'" />
                  <iDEAL v-if="payment.id === 'ideal'" />
                  <bancontact v-if="payment.id === 'bancontact'" />
                </b-tab>
              </b-tabs>
            </div>
          </b-tab>

          <b-tab
            :active="tabActive === 'refund_history'"
            @click="handleSelectTab({tab: 'refund_history'})"
          >
            <template #title>
              <span class="tab-title">{{ $t('payment.autoRefundFeature.txtRefundHistory') }}</span>
            </template>
            <tab-history-refund />
          </b-tab>
        </b-tabs>
      </div>

      <div v-else-if="!(hasLocalBank && onlyOnePaymentMethod)">
        <b-row>
          <b-col
            class="tabs-mf tabs-mf-custom tabs-payment"
            :class="[!hasStepGuide ? 'bg-tabs' : 'bg-tabs-step']"
          >
            <div v-if="isStepCreateAdAccount && isSetupCostModel">
              <setup-cost-success />
            </div>
            <b-tabs
              v-else
              :key="hasPayFast"
              ref="myTabs"
              id="list-payment-method"
              data-testid="list-payment-method"
              :class="hasStepGuide ? 'list-tabs-payment-has-step' : 'list-tabs-payment'"
            >

              <div
                v-if="isShowBtnScrollLeft"
                class="btn-scroll-tab-left"
                @click="scroll_left"
              >
                <feather-icon
                  class="btn-chevrons"
                  icon="ChevronsLeftIcon"
                />
              </div>
              <div
                v-if="isShowBtnScrollRight"
                class="btn-scroll-tab-right"
                @click="scroll_right"
              >
                <feather-icon
                  class="btn-chevrons"
                  icon="ChevronsRightIcon"
                />
              </div>

              <b-tab
                v-for="(payment, index) in listPaymentMethod"
                :key="index"
                :id="`trigger-${payment.id}-add-fund`"
                :data-testid="`trigger-${payment.id}-add-fund`"
                :active="tab === payment.id"
                @click="handleUrlTab(payment.id)"
              >
                <template #title>
                  <div class="w-100 h-100 d-flex flex-column justify-content-between">
                    <div class="content-title-tab">
                      <p
                        :id="`trigger-${payment.id}-add-fund-child`"
                        class="tab-title-payment-method"
                      >
                        {{ payment.name }}
                      </p>

                      <p
                        :id="`text-${payment.id}-fee-payment`"
                        class="text-fee-payment"
                      >
                        (Fee: {{ payment.gateFee }})
                      </p>
                    </div>

                    <div class="d-flex flex-row-reverse justify-content-between w-100">
                      <p
                        v-if="payment.id === 'payoneer-ticket' && hasPayoneer"
                        class="mb-0 font-12 font-weight-light text-placeholder"
                      >
                        Ticket
                      </p>
                      <b-img
                        :data-testid="`${payment.id}-logo`"
                        alt="card"
                        :height="23"
                        :src="payment.LogoFullPayment"
                      />
                    </div>
                    <span
                      v-if="false"
                      class="ribbon position-absolute"
                    >Free fee</span>
                  </div>
                </template>
                <credit-card v-if="payment.id === 'creditcard'" />
                <paypal-card v-if="payment.id === 'paypal'" />
                <payoneer v-if="payment.id === 'payoneer'" />
                <PayoneerCard v-if="payment.id === 'payoneer-ticket'" />
                <wise-transfer-card v-if="payment.id === 'wise'" />
                <USDT-card v-if="payment.id === 'usdt'" />
                <airwallex-new v-if="payment.id === 'airwallex'" />
                <tazapay-card v-if="payment.id === 'tazapay'" />
                <lian-lian-card v-if="payment.id === 'lianlian'" />
                <PayFastCard v-if="payment.id === 'payfast'" />
                <local-bank v-if="payment.id === 'local-bank'" />
                <iDEAL v-if="payment.id === 'ideal'" />
                <bancontact v-if="payment.id === 'bancontact'" />
              </b-tab>
              <b-tab
                v-if="isEmediaPlatform"
                :id="`trigger-national-banks-add-fund`"
                :data-testid="`trigger-national-banks-add-fund`"
                :active="tab === 'national-banks'"
                @click="handleUrlTab('national-banks')"
              >
                <template #title>
                  <div class="w-100 h-100 d-flex flex-column justify-content-between">
                    <div class="content-title-tab">
                      <p
                        :id="`trigger-national-banks-add-fund-child`"
                        class="tab-title-payment-method"
                      >
                        National Banks
                      </p>

                      <p
                        :id="`text-national-banks-fee-payment`"
                        class="text-fee-payment"
                      >
                        (Fee: 0.00%)
                      </p>
                    </div>

                    <div
                      class="d-flex justify-content-end align-items-center w-100"
                      style="font-size: 12px"
                    >
                      <b-img
                        alt="card"
                        :height="16"
                        :src="require('@/assets/images/pages/payment/ic-local-bank.png')"
                        style="margin-right: 2px"
                      />
                      <p class="mb-0 text-secondary">
                        National Banks
                      </p>
                    </div>
                  </div>
                </template>
                <b-row class="width-content">
                  <b-col
                    class="list-card bg-white p-2 mb-2"
                    :class="hasStepGuide ? 'radius-content top-up-on-step' : 'top-up-form-container'"
                    :md="hasStepGuide ? 12 : 9"
                    cols="12"
                  >
                    Please contact
                    <a
                      href="https://wa.me/message/7YS4KG2OBXNXF1"
                      target="_blank"
                      class="text-gradient"
                    >
                      Emedia Maroc support team
                    </a>
                    to make top ups via local bank methods
                  </b-col>
                  <div class="user-hint mt-1 mb-2 d-flex">
                    <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
                  </div>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <local-bank />
      </div>
      <div
        v-if="hasStepGuide"
        class="d-flex  justify-content-center"
        :class="tabActive === 'refund_history' ? 'my-2' : 'mb-2'"
      >
        <btn-loading
          id="btn-back-previous"
          data-testid="btn-back-previous"
          variant-convert="btn-back"
          @click="$router.push({ name: 'waiting-verify-information' })"
        >
          {{ $t('stepGuideControl.stepWaitingVerify.textBackToPreviousStep') }}
        </btn-loading>
        <btn-loading
          v-if="isStepRequestAdAccount"
          id="btn-create-ad-account"
          data-testid="btn-create-ad-account"
          class="ml-1"
          variant-convert="btn-submit"
          @click="$router.push({ name: 'my-ads-account' })"
        >
          {{ $t('stepGuideControl.textCreateAdAccount') }}
        </btn-loading>
      </div>

      <popup-auto-refund
        :show-side-bar.sync="isShowAutoRefundPopup"
        :list-used-payment-methods="listUsedPaymentMethods"
        @handelNextRequest="handelNextRequest"
      />

      <modal-confirm-auto-refund
        ref="modal-confirm-request-refund"
        :info-request-refund="infoRequestRefund"
        @submitRequestRefundSuccess="submitRequestRefundSuccess"
      />

      <modal-request-refund-success
        ref="modal-request-refund-success"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BImg,
  BCardText,
} from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { ONBOARDING_STEP, TICKET_TYPE } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import numberFormatMixin from '@/mixins/numberFormatMixin'
// import TableGatewayFees from '@/views/payment/components/TableGatewayFees.vue'
import TazapayCard from '@/views/payment/components/Tazapay/TazapayCard.vue'
import paymentMixin from '@/mixins/paymentMixin'
import LocalBank from '@/views/payment/components/LocalBank/LocalBank.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import PayoneerCard from '@/views/payment/components/PayoneerCard/PayoneerCard.vue'
import PayFastCard from '@/views/payment/components/PayFast/PayFastCard.vue'
import authMixin from '@/mixins/authMixin'
import UserHint from '@/components/UserHint.vue'
import iDEAL from '@/views/payment/components/iDEAL/iDEAL.vue'
import Bancontact from '@/views/payment/components/Bancontact/Bancontact.vue'
import SetupCostSuccess from '@/views/payment/components/SetupCostSuccess.vue'
import AirwallexNew from './components/AirwallexNew/AirwallexNew.vue'
import WiseTransferCard from './components/WiseTransferCard/WiseTransferCard.vue'
import Payoneer from './components/Payoneer/Payoneer.vue'
import CreditCard from './components/CreditCard/CreditCard.vue'
import USDTCard from './components/USDTCard/USDTCard.vue'
import PaypalCard from './components/Paypal/PaypalCard.vue'
import LianLianCard from './components/LianLian/LianLianCard.vue'
import PopupAutoRefund from './components/PopupAutoRefund.vue'
import ModalConfirmAutoRefund from './components/ModalConfirmAutoRefund.vue'
import ModalRequestRefundSuccess from './components/ModalRequestRefundSuccess.vue'
import TabHistoryRefund from './components/TabsHistory/TabHistoryRefund.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapActions: mapActionsAdsAccount } = createNamespacedHelpers(
  'adsAccount',
)
// eslint-disable-next-line no-unused-vars
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    SetupCostSuccess,
    Bancontact,
    iDEAL,
    UserHint,
    BtnLoading,
    PayFastCard,
    PayoneerCard,
    BTabs,
    BTab,
    BImg,
    BRow,
    BCol,
    BCardText,

    // components
    // TableGatewayFees,
    Payoneer,
    CreditCard,
    USDTCard,
    PaypalCard,
    AirwallexNew,
    WiseTransferCard,
    TazapayCard,
    LianLianCard,
    LocalBank,
    PopupAutoRefund,
    ModalConfirmAutoRefund,
    ModalRequestRefundSuccess,
    TabHistoryRefund,
  },

  mixins: [envMixin, stepGuideMixin, numberFormatMixin, paymentMixin, generalConfigsMixin, authMixin],
  data() {
    return {
      tab: this.$route.query.tab || 'paypal',
      isShowAutoRefundPopup: false,
      infoRequestRefund: {},
      tabActive: this.$route.query.tabActive || '',
      accountLoading: false,
      isShowBtnScrollLeft: false,
      isShowBtnScrollRight: false,
      scrollTab: 0,
    }
  },

  computed: {
    ...mapGetters(['listTicket', 'loading', 'listRefundUserHistory', 'listUsedPaymentMethods']),
    ...mapGettersAuth(['user', 'generalConfigs', 'isTikTokAppCenter']),

    totalRequestRefund() {
      return this.listRefundUserHistory?.paging?.total
    },

    isStepRequestAdAccount() {
      return this.setupGuideStep === ONBOARDING_STEP.REQUESTING_ADS_ACCOUNT
    },

    amountBalance() {
      return this.user?.data?.balance
    },

    // isPaymentSuccess() {
    //   return (this.isShowTabs || this.listTicket.paging.total === 0) && this.hasStepGuide
    // },
  },

  watch: {
    $route: {
      immediate: true,
      handler(val) {
        this.tab = val?.query?.tab || 'paypal'

        if (val?.query?.tabActive) {
          this.tabActive = val?.query?.tabActive
        }
      },
    },
  },

  async created() {
    const params = {
      type: TICKET_TYPE.RECHARGE_USER_BALANCE,
      status: ['opening'],
    }
    await this.getListTicket({ params })
    this.getAccountList()

    await document.querySelector('#list-payment-method__BV_tab_controls_')?.addEventListener('scroll', event => this.handleScrollBar(event))
    await this.$nextTick(() => {
      this.checkHasScrollbar()
      this.addClassNavBar()
    })

    this.fetchListUsedPaymentMethods()
  },

  methods: {
    ...mapActions(['getListTicket', 'getListUsedPaymentMethods']),
    ...mapActionsAdsAccount(['getAccountList']),
    ...mapActionsAuth(['getAccountInfo']),

    checkHasScrollbar() {
      const content = document.querySelector('#list-payment-method__BV_tab_controls_')
      if (content) {
        this.isShowBtnScrollRight = content.scrollWidth > content.clientWidth
      }
    },

    async addClassNavBar() {
      const content = await document.querySelector('.nav-tabs').parentElement
      await content.classList.add('nav-bar-container')
    },

    handleScrollBar() {
      const content = document.querySelector('#list-payment-method__BV_tab_controls_')
      const scrollEnd = content.scrollWidth - content.clientWidth

      this.isShowBtnScrollRight = Math.round(content.scrollLeft - 0.5) !== scrollEnd
      this.isShowBtnScrollLeft = content.scrollLeft !== 0
    },

    scroll_left() {
      const content = document.querySelector('#list-payment-method__BV_tab_controls_')
      const scrollMin = 0
      content.scrollTo({
        top: 0,
        left: Math.min(this.scrollTab -= 30, scrollMin),
        behavior: 'smooth',
      })
    },

    scroll_right() {
      const content = document.querySelector('#list-payment-method__BV_tab_controls_')
      const widthTab = 895
      content.scrollTo({
        top: 0,
        left: Math.max(this.scrollTab += 30, widthTab),
        behavior: 'smooth',
      })
    },

    handelNextRequest(params) {
      this.infoRequestRefund = params
      this.$refs['modal-confirm-request-refund'].showModal()
    },

    submitRequestRefundSuccess() {
      this.isShowAutoRefundPopup = false
      this.$refs['modal-request-refund-success'].showModalRequestSuccess()
    },

    handleAutoRefund() {
      this.isShowAutoRefundPopup = true
    },

    handleSelectTab({ tab }) {
      this.tabActive = tab
      if (this.isOnlyEcomdyPlatform) {
        this.$router.push(`/payment/add-fund?tabActive=${tab === 'refund_history' ? tab : `${tab}&tab=paypal&type=recharge`}`)
      } else {
        this.$router.push(`/payment/add-fund?tabActive=${tab === 'refund_history' ? tab : `${tab}&type=recharge`}`)
      }
    },

    onClickBusdTab() {
      // Revert when open busd open.
      this.tab = 'busd'
      this.$router.push('/payment/add-fund?tab=busd')
    },

    goToAddFund() {
      if (this.isOnlyEcomdyPlatform) {
        this.$router.push('/payment/add-fund?tab=paypal')
      } else {
        this.$router.push('/payment/add-fund')
      }
    },

    async handleUrlTab(tab) {
      this.tab = tab
      const y = window.scrollY
      await this.$router.push(`/payment/add-fund?tab=${tab}`)
      window.scrollTo(0, y)
    },

    goToAddFundGuide() {
      window.open(process.env.VUE_APP_GUIDE_TO_TOP_UP_COMMON)
    },

    gotoDetail() {
      window.open('https://docs.ecomdymedia.com/getting-started/plan-pricing')
    },

    continueAddFund() {
      const idToken = localStorage.getItem('id_token')
      if (this.isOnlyEcomdyPlatform) {
        window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?tab=paypal&id_token=${idToken}`)
      } else {
        window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?id_token=${idToken}`)
      }
    },

    async handleRefreshPage() {
      this.accountLoading = true
      await this.getAccountInfo()
      this.accountLoading = false

      if (this.user.data.onboardingStep === 5) {
        await this.$router.push('/my-ads-account')
      }
    },

    async fetchListUsedPaymentMethods() {
      await this.getListUsedPaymentMethods()
    },

  },
}
</script>
<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

// .hidden-tabs .nav-tabs {
//   display: none !important;
// }

.tab-refund-on-step-guide {

  .nav-tabs {
    margin-bottom: 24px;
  }
}

.bg-tabs .nav-tabs {
  background-color: transparent;
  margin-bottom: 0;
  display: flex;

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.tabs-payment .nav-tabs {
  padding: 1rem 1.5rem 0 0;

  .nav-link {
    padding-top: 0;
    padding-bottom: 14px;

    @media (max-width: 1080px) {
      padding-bottom: 0px;
      margin-bottom: 14px;
      margin-right: 6px;
    }
  }
}

// .tabs-payment .nav-tabs {
//   padding: 1rem 1.5rem 0 0 !important;
// }

.bg-tabs-step .nav-tabs {
  background-color: #fff;
  // border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
  margin-bottom: 0px;
  padding: 1rem 0 0 !important;
}

.bg-tabs-step .nav-link {
  margin-right: 0px;
}

.tabs-has-step {
  .nav-tabs {
    .nav-item {
      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: .5rem;
      }
      @media (min-width: 768px) and (max-width: 1549px) {
        margin-right: 4px;
        margin-bottom: .5rem;
      }
    }
    @media (min-width: 1550px) {
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}

.list-tabs-payment, .list-tabs-payment-has-step {
  position: relative;

  .tab-content {
    margin-top: 60px;
  }

  // .nav-bar-container {
  //   position: sticky;
  //   top: 1rem;
  //   z-index: 1;
  //   background-color: #f5f5f5;
  // }

  .nav-tabs {
    padding: 0;
    flex-wrap: nowrap;
    position: relative;

    .nav-item {
      margin: 0 24px 24px 0;

      .nav-link {
        width: var(--tab-payment-width) !important;
        height: 100px;
        margin: 0;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);

        &.active {
          border-radius: 10px;
          background: transparent;
          background-image: linear-gradient(90deg, white, white),
            linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
          background-clip: padding-box, border-box;
          background-origin: border-box;
          border: 1px solid transparent;

          .content-title-tab {
            color: #000;
          }

          .text-fee-payment {
            background: linear-gradient(128deg, #81fff4 -29.89%, #6e44ff 98.77%);
            -webkit-background-clip: text !important;
            -webkit-text-fill-color: transparent;
          }

          &::after {
            background: none !important;
            height: 0;
            transform: translate3d(0, 150%, 0);
          }
        }
      }

      &:last-child {
        margin: 0 0 24px 0;
      }
    }

    @media screen and (max-width: 1279.98px) {
      overflow-x: scroll;
    }
  }
}

.list-tabs-payment-has-step {

  .tab-content {
    margin-top: 0;
  }

  .nav-bar-container {
    background-color: #fff;
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
    padding: 1.5rem 1.5rem 0;
  }

  .nav-link {
    width: var(--tab-payment-width) !important;
    height: 97px !important;
  }

  .nav-tabs {
    overflow-x: scroll;
    padding: 0 !important;
  }

  .btn-scroll-tab-left {
    top: 60px;
    left: 1.5rem;
  }

  .btn-scroll-tab-right {
    top: 60px;
    right: 1.5rem;
  }
}

.btn-scroll-tab-left {
  position: absolute;
  top: 35px;
  left: 0;
  cursor: pointer;
}

.btn-scroll-tab-right {
  position: absolute;
  top: 35px;
  right: 0;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.tiktok-add-fund-container {
  border-radius: var(--border-radius-base);
}

.btn-back {
  padding: 10px 20px;
}

.btn-auto-refund-container {
  margin-left: 12px;
}

.tab-title {
  display: flex;
  align-items: center;
}

.tab-title-payment-method {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
}

.text-fee-payment {
  color: #000000B2;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
}

.table-gateway-fees {
  border-radius: var(--table-border-radius);
  overflow-x: auto;
}

.tab-full {
  width: 100%;

  @media (min-width: 992px) and (max-width: 1080px) {
    //width: calc(858.728px - 24px);
    width: calc(858.728px - 119px);
  }
  @media (min-width: 1081px) {
    //width: calc(1033.728px - 24px);
    width: calc(1033.728px - 119px);
  }
}

.btn-refresh-add-fund {
  color: rgba(38, 103, 255, 1);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.icon-refresh-add-fund {
  color: #FE2C55;
  margin-right: 2px;
  &.spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
}

.btn-scroll-tab-left, .btn-scroll-tab-right {
  background-color: rgba(0, 0, 0, .1);
  padding: .5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  background: transparent;
  background-image: linear-gradient(90deg, white, white),
    linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 1px solid transparent;

  .btn-chevrons {
    margin: 0;
  }
}

.width-content {
  max-width: 1200px;
  margin: 0px auto;

  @media (max-width: 992px) {
    justify-content: center;
  }

  .list-card {
    border-radius: var(--border-radius-base);
  }

  .radius-content {
    border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base) !important;
  }
}

.text-warning-balance {
  margin-top: 6px;
}

.ribbon {
  width: 80px;
  font-size: 10px;
  padding: 2px;
  right: -24px;
  top: 8px;
  text-align: center;
  transform: rotate(45deg);
  background: linear-gradient(128deg, #81fff4 -29.89%, #6e44ff 98.77%);
  color: white;
}

.text-warning-balance {
  margin-top: 6px;
}
</style>
